import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import EnvData from '../components/EnvData';
import {PRIMARY_COLOR_3} from '../constants';
import PlainPageContainer from '../components/PlainPageContainer';
import Separator from '../components/Separator';

export default function PolitikaPrivatnostiPage({location}) {
	return (
		<EnvData>
			<ThePage uri={location.pathname} />
		</EnvData>
	);
}

PolitikaPrivatnostiPage.propTypes = {
	// eslint-disable-next-line
	location: PropTypes.object
};

const ThePage = function ({uri}) {
	return (
		<Layout title="Uvjeti korištenja" description="Pročitajte naše uvjete korištenja" uri={uri}>
			<Nav background={PRIMARY_COLOR_3} />
			<PlainPageContainer>
				<h1>Uvjeti korištenja</h1>

				<p>
					Internet aplikacija app.qr-cjenik.hr i sve vezane domene (nas.qr-cjenik.hr, web.qr-cjenik.hr, danasnji.menu, cjenik.today,
					najbolji.bar, najbolji.pub i najbolja.pizza) (u daljnjem tekstu Aplikacija) i Internet stranica qr-cjenik.hr (u daljnjem tekstu
					Stranica) autorsko su djelo i intelektualno vlasništvo društva Splendido Solutions d.o.o., sa sjedištem u Varaždinu, Stjepana Vukovića 8B,
					OIB: 42172723674 (u daljnjem tekstu Firma). Korisnik je svaka osoba koja pristupi Stranici, dok je Korisnik aplikacije svaka osoba
					kojoj je Firma omogućila korištenje Aplikacije kao i osoba koja pristupa Aplikaciji na temelju odobrenja osobe kojoj je Firma
					omogućila korištenje Aplikacije.
				</p>

				<p>
					Dokumenti, podaci i informacije objavljeni na Stranici ne smiju se reproducirati, distribuirati ili na bilo koji način koristiti u
					komercijalne svrhe bez izričitog pristanka Firme ili na bilo koji način koji može uzrokovati štetu Firmi ili bilo kojoj trećoj
					strani.
				</p>

				<p>
					Zabranjeno je prenošenje, mijenjanje, reproduciranje, sudjelovanje u prijenosu ili prodaji, distribucija, izrada kopija,
					otpremanje te svaka druga vrsta uporabe sadržaja i programskih kodova ovih Stranica, Aplikacije ili jednog njihova dijela te
					odabir i usklađivanje njihova sadržaja, bez izričitog prethodnog pismenog dopuštenja Firme.
				</p>

				<p>Korisnicima nije dopušteno:</p>

				<ul>
					<li>
						objavljivanje, slanje i razmjena sadržaja koji krše postojeće hrvatske i/ili međunarodne zakone, sadržaj koji je uvredljiv,
						vulgaran, pornografski, prijeteći, rasistički ili šovinistički te štetan na bilo koji drugi način koji mi procijenimo
						neprimjerenim
					</li>
					<li>
						objavljivanje, slanje i razmjena informacija za koje Korisnik zna ili pretpostavlja da su lažne, a čije bi korištenje moglo
						nanijeti štetu drugim Korisnicima
					</li>
					<li>lažno predstavljanje u ime druge pravne ili fizičke osobe</li>
					<li>manipuliranje informacijama u svrhu skrivanja porijekla sadržaja kojeg se objavljuje ili šalje putem ove Aplikacije</li>
					<li>objavljivanje, slanje i razmjena sadržaja koji su zaštićeni autorskim pravom</li>
					<li>svjesno objavljivanje, slanje i razmjena sadržaja koji sadrži viruse ili slične računalne datoteke</li>
					<li>korištenje, na bilo koji način, Firminih stranica za slanje komercijalnih priopćenja (SPAM)</li>
					<li>
						prikupljanje, čuvanje i objavljivanje bilo čijih osobnih podataka: drugih Korisnika i Korisnika Aplikacije ili bilo koga tko ne
						koristi usluge Firminog weba
					</li>
				</ul>

				<p>
					Sadržaj koji smatramo nepodobnim ili ne odgovara uvjetima korištenja bit će izbrisan, a korisnički račun registriranog Korisnika
					Aplikacije ćemo bez prethodne najave ukinuti zbog kršenja uvjeta korištenja.
				</p>

				<p>
					Korištenjem Aplikacije i/ili Stranice korisnik prihvaća sve rizike koji nastaju iz korištenja te prihvaća koristiti Aplikaciju
					i/ili Stranicu isključivo za osobnu uporabu i na vlastitu odgovornost.
				</p>

				<p>
					Firma se u potpunosti odriče svake odgovornosti koja na bilo koji način može nastati iz, ili je na bilo koji način vezana za
					korištenje Aplikacije i/ili Stranice, za bilo koje radnje Korisnika uporabom ili zlouporabom Aplikacije i/ili Stranice, te za bilo
					kakvu štetu koja može nastati korisniku ili bilo kojoj trećoj strani u vezi s uporabom ili zlouporabom korištenja Aplikacije i/ili
					Stranice.
				</p>

				<p>
					Firma neće snositi odgovornost ni za kakvu štetu niti za bilo kakvu drugu vrstu izravnog ili neizravnog gubitka (npr. gubitak
					dobiti, gubitak određenog klijenta ili tržišta, gubitak financijskih ili bilo kakvih drugih podataka, narušavanje reputacije,
					gubitak poslovne mogućnosti) koji bi nastupio Korisniku ili Korisniku aplikacije zbog nemogućnosti korištenja Aplikacije i/ili
					Stranice uslijed neodgovarajućeg postupanja korisnika, nedostupnosti Aplikacije i/ili Stranice nastale kao posljedica tehničkih
					problema ili više sile, grešaka u radu Aplikacije i/ili Stranice, neadekvatnosti Aplikacije i/ili Stranice za poslovne potrebe
					korisnika.
				</p>

				<p>
					Korisniku aplikacije pruža se najam Aplikacije u konačnom obliku, po principu &quot;kakav jest&quot; (eng. &quot;as is&quot;) te
					se isključuje bilo kakvo jamstvo glede bilo kakvih nedostataka Aplikacije. Iako Firma radi kontinuirano testiranje i održavanje
					Aplikacije, ne može jamčiti da prilikom korištenja Aplikacije neće doći do pogrešaka u radu same Aplikacije, te da tom prilikom
					neće doći do eventualnih kvarova. U svakom slučaju, Firma će poduzeti sve korake da kvar otkloni u najkraćem mogućem roku.
				</p>

				<p>
					Firma ne odgovara Korisniku aplikacije za štetu nastalu uslijed gubitka ili uništenja podataka, nedopuštenog pristupa, nedopuštene
					promjene, nedopuštenog objavljivanja ili bilo koje druge zlouporabe, a posebno kada su navedene okolnosti uzrokovane višom silom,
					kvarom opreme, pogrešnim rukovanjem, utjecajem drugih licenciranih i nelicenciranih računalnih programa, virusa i ostalih štetnih
					utjecaja.
				</p>

				<p>
					Korisnik Aplikacije je dužan čuvati svoju lozinku tajnom i ne smije ju podijeliti s trećim osobama. Firma je u Aplikaciji
					osigurala sve potrebne alate da Korisnik ni u jednom trenutku nema potrebu podijeliti svoju lozinku s trećim osobama, a Firma
					nikada, ni u jednom slučaju, neće Korisnika pitati za njegovu lozinku.
				</p>

				<p>
					Komentari, mišljenja i ideje koje su dostavljene Firmi od strane Korisnika ili Korisnika aplikacije se neće smatrati povjerljivim
					informacijama te će ih Firma moći koristiti, reproducirati, distribuirati i/ili prerađivati u bilo koju svrhu (u cijelosti ili
					djelomično, privremeno ili trajno, bilo kojim sredstvom i u bilo kojem obliku) i to bez obveza plaćanja bilo kakve naknade
					Korisniku ili Korisniku aplikacije. S tim u vezi, Korisnik, odnosno Korisnik aplikacije se odriče prava na postavljanje, odnosno
					podnošenje bilo kakvog zahtjeva prema Firmi u svezi bilo kakve njegove moguće odgovornosti.
				</p>

				<p>
					Verbalni, fizički, pisani ili bilo koji drugi način neprimjerene komunikacije i ponašanja od strane Korisnka i/ili Korisnika
					aplikacije prema bilo kojem drugom Korisniku i/ili Korisniku aplikacije, zaposleniku i/ili suradniku Firme rezultirat će
					neposrednim raskidom suradnje i deaktivacijom korisničkog računa.
				</p>

				<p>
					Prijavom u sustav Aplikacije, smatrat će se da je Korisnik aplikacije pročitao, razumio i prihvatio kao obvezujuće Uvjete
					korištenja koji su u tom trenutku objavljeni kao važeći.
				</p>

				<p>
					Firma zadržava pravo promjene bilo kojih resursa (programskih kodova, multimedijskih sadržaja, podataka, skripti i ostalih
					podataka i informacija) koji se nalaze na Stranici ili Aplikaciji u bilo koje vrijeme i bez prethodne najave ili posebne
					obavijesti i bez potrebe ishođenja prethodne suglasnosti Korisnika ili Korisnika aplikacije.
				</p>

				<p>
					Firma zadržava pravo izmjene ovih uvjeta korištenja i korištenja Stranice i Aplikacije u bilo kojem trenutku te neće biti
					odgovorno ni za kakve moguće posljedice proizašle iz takvih promjena. Navedene promjene stupaju na snagu objavljivanjem na ovim
					Internet stranicama ili kada su korisnici o njima obaviješteni. Promijenjeni uvjeti korištenja odnose se na sve postojeće
					Korisnike aplikacije i na sve buduće Koisnike aplikacije.
				</p>
			</PlainPageContainer>
			<Separator />
			<Footer />
		</Layout>
	);
};

ThePage.propTypes = {
	uri: PropTypes.string
};
