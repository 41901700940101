import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {CONTAINER_BLOG_WIDTH, CONTAINER_WIDTH, TEXT_MUTED1} from '../constants';

export default function PlainPageContainer({children = null}) {
	return <Wrapper>{children}</Wrapper>;
}

PlainPageContainer.propTypes = {
	children: PropTypes.node
};

const Wrapper = styled.div`
	display: block;
	width: 100%;
	max-width: ${CONTAINER_BLOG_WIDTH};
	margin: 3rem auto;
	padding: 0 1rem;
	box-sizing: border-box;

	h1,
	h2,
	h3 {
		line-height: 1.5;
		font-weight: normal;
		text-align: left;
		margin: 0;
	}

	h1 {
		font-size: 1.5rem;
		font-weight: bold;
		margin: 1rem 0;
	}

	h2 {
		font-size: 1.2rem;
		font-weight: normal;
	}

	h3 {
		font-size: 1.05rem;
		font-weight: normal;
	}

	p {
		color: ${TEXT_MUTED1};
		line-height: 1.5;
		font-size: 0.88rem;
		margin: 1rem 0;
	}

	ul,
	ol {
		margin: 1rem 0;
		padding: 0 0 0 1.25rem;
		list-style-position: outside;

		> li {
			color: ${TEXT_MUTED1};
			line-height: 1.5;
			font-size: 0.88rem;
			margin: 1rem 0;
		}
	}

	a {
		color: ${TEXT_MUTED1};
		border-bottom: 1px dashed ${TEXT_MUTED1};

		&:hover {
			border-bottom-style: solid;
		}
	}
`;
